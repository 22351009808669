import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Maisons de Luxe | Premium homes for corporate professionals
			</title>
			<meta name={"description"} content={"Maisons de Luxe , We make your relocation a smooth, stress-free and pleasant experience."} />
			<meta property={"og:title"} content={"Maisons de Luxe |  Premium homes for corporate professionals"} />
			<meta property={"og:description"} content={"Maisons de Luxe , We make your relocation a smooth, stress-free and pleasant experience."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/favicon.png?v=2023-08-13T18:41:29.827Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/favicon.png?v=2023-08-13T18:41:29.827Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/favicon.png?v=2023-08-13T18:41:29.827Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/favicon.png?v=2023-08-13T18:41:29.827Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/favicon.png?v=2023-08-13T18:41:29.827Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/favicon.png?v=2023-08-13T18:41:29.827Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/favicon.png?v=2023-08-13T18:41:29.827Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-light">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/brand.png?v=2023-08-13T18:15:52.374Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1Lg"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					PREMIUM HOMES FOR CORPORATE PROFESSIONALS
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					We make your relocation a smooth, stress-free and pleasant experience.
					<Link href="mailto:info@maisonsdeluxe.co.uk">
						<br />
					</Link>
				</Text>
				<Link
					href="mailto:info@maisonsdeluxe.co.uk"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="#305d4d"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
					border-color="#000000"
					box-shadow="--m"
				>
					Contact us
				</Link>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-flex-direction="column"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				sm-min-width="280px"
			/>
			<Text
				color="--darkL2"
				font="--headline2Lg"
				width="20%"
				text-align="right"
				lg-font="--headline2Lg"
				lg-width="100%"
				lg-text-align="left"
				sm-margin="16px 0px 0px 0px"
			>
				Premium Shared Homes
			</Text>
			<Image
				src="https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/Room%201.jpg?v=2023-08-13T19:01:51.612Z"
				display="block"
				width="60%"
				sm-width="100%"
				sm-order="-1"
			/>
			<Box width="20%" md-width="100%" lg-width="35%">
				<Text
					color="--darkL2"
					margin="0px 0px 12px 0px"
					font="--lead"
					lg-width="100%"
					sm-width="100%"
					md-width="540px"
				>
					We pride ourselves in creating the best shared homes in Birmingham. Our homes are beautifully furnished, extremely well maintained and in the most sought after locations.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				width="100%"
				max-width="1280px"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="60%"
				grid-gap="20px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				sm-width="100%"
			>
				<Image src="https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/Room%206.JPEG?v=2023-08-13T19:03:14.596Z" display="block" width="100%" />
			</Box>
			<Box
				width="40%"
				lg-width="100%"
				lg-display="flex"
				lg-flex-direction="column"
				lg-align-items="flex-end"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 0px 0px" font="--headline2Lg">
						Premium People
					</Text>
				</Box>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					width="90%"
					lg-max-width="540px"
					lg-width="100%"
				>
					We have long waiting lists for each home so we have the luxury of handpicking each new housemate to make sure they are a great fit for the house. Our housemates are young, vibrant, accomplished professionals who are in Birmingham to work hard and make a mark!
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				flex-wrap="wrap"
				max-height="1200px"
				max-width="1280px"
				align-content="flex-start"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-width="100%"
				lg-max-width="none"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Image
				src="https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/Room%205.JPEG?v=2023-08-13T19:07:42.254Z"
				display="block"
				width="50%"
				lg-order="-1"
				sm-width="100%"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--headline2Lg"
				width="20%"
				text-align="left"
				order="-1"
				lg-width="100%"
				lg-font="--headline2Lg"
				sm-margin="16px 0px 0px 0px"
			>
				Premium Experience
			</Text>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--lead"
				width="25%"
				lg-width="100%"
				lg-max-width="540px"
			>
				We make your relocation to Birmingham a smooth, stress-free and pleasant experience. We can help you orientating yourself in the new area, with any essential paperwork (such as registering with the local GP or opening a UK bank account) and we provide ongoing support and assistance and on-hand maintenance while you are in any one of our Premium Homes.
			</Text>
		</Section>
		<Section background="#305d4d" padding="80px 0 80px 0" color="#305d4d">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ready for your next step?
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Get in touch with our team and find out if Maisons de Luxe can help you.
				</Text>
				<Link
					text-align="center"
					color="--light"
					font="normal 700 24px/1.2 --fontFamily-googlePoppins"
					text-decoration-line="underline"
					margin="0px 0px 16px 0px"
					href="mailto:mailto:info@maisonsdeluxe.co.uk?"
				>
					info@maisonsdeluxe.co.uk
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Image src="https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/DPS-logo.svg?v=2023-08-13T18:48:46.674Z" display="block" />
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Image src="https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/NRLA-Logo.svg?v=2023-08-13T18:49:01.105Z" display="block" />
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Image src="https://uploads.quarkly.io/64d917586efcea001ae52ff4/images/PRS-Logo.svg?v=2023-08-13T18:49:11.784Z" display="block" />
				</Box>
			</Box>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});